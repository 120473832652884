import styled from "styled-components";

export const Nav = styled.div`
  /* background: ${({ scrolled }) => (scrolled ? "#333444" : "#000000")}; */
  /* background: #d6d9e0; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* padding: 2rem 0; */
  position: fixed;
  top: 0;
  height: 7rem;
  width: 100%;
  z-index: 9997;
  border-bottom: 1px solid #d6d9e0;
  margin: 0;
  .container {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .navbarWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 90%;
    margin: 0 auto;
  }
  @media screen and (min-width: 1024px) {
    .navbarWrapper {
      width: 100%;
    }
  }
`;

export const NavBrand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* width: 7rem; */
  width: 5rem;
  /* background: #000000; */
  /* background: #66d1ff; */
  img {
    /* background: #ffffff; */
    height: 4rem;
    object-fit: cover;
    display: block;
    /* border: 1px solid #d6d9e0; */
    border-radius: 0.5rem;
    cursor: pointer;
  }
`;

export const BurgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .navbar-item:hover {
    transition: 0.3s ease-in-out;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    .content {
      margin-bottom: 0;
    }
    .button:last-of-type {
      margin-left: auto;
    }
  }

  @media screen and (max-width: 768px) {
    /* flex-flow: column nowrap; */
    background: #ffffff;
    transform: ${({ active }) =>
      active ? "translateX(0)" : "translateX(-130%)"};
    position: absolute;
    transition: 0.2s ease-in-out;
    top: 7rem;
    left: 0;
    height: calc(100vh - 7rem);
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.15rem;

    .button {
      margin: 2rem;
      margin-top: 1rem;
    }
  }
`;

export const MenuIcon = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;

  div {
    width: 2rem;
    height: 0.25rem;
    background: #000000;
    /* background: ${({ active }) => (active ? "#cccccc" : "#ffffff")}; */
    border-radius: 5rem;
    transform-origin: 1px;
    transition: 0.3s ease-in-out;

    &:nth-child(1) {
      transform: ${({ active }) => (active ? "rotate(45deg)" : "rotate(0deg)")};
    }

    &:nth-child(2) {
      opacity: ${({ active }) => (active ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ active }) =>
        active ? "rotate(-45deg)" : "rotate(0deg)"};
    }
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;
