import React, { useState } from "react";
import * as S from "./Nav.styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Logo from "../../assets/LKTRAnewLogo-Photoroom.png";
import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { library } from "@fortawesome/fontawesome-svg-core";

// library.add(fas);

const Nav = () => {
  const [active, setActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const publicLinks = [
    {
      id: 1,
      url: "/",
      title: "Pradžia",
    },
    {
      id: 2,
      url: "/news?page=1",
      title: "Naujienos",
    },
    {
      id: 3,
      url: "/license",
      title: "Pažymėjimai",
    },
    {
      id: 4,
      url: "/studies",
      title: "Studijos",
    },
    {
      id: 5,
      url: "/register",
      title: "Registracija",
    },
  ];

  const privateLinks = [
    {
      id: 1,
      url: "/account",
      title: "Paskyra",
    },
    {
      id: 2,
      url: "/account/material",
      title: "Metodinė Medžiaga",
    },
    {
      id: 3,
      url: "/account/clinics",
      title: "Kursai ir Seminarai",
    },
    {
      id: 4,
      url: "/account/settings",
      title: "Nustatymai",
    },
  ];

  const adminLinks = [
    {
      id: 1,
      url: "/admin/admin-account",
      title: "Account",
    },
    {
      id: 2,
      url: "/admin/admin-clinics",
      title: "Clinics",
    },
    {
      id: 3,
      url: "/admin/admin-news",
      title: "News",
    },
    {
      id: 4,
      url: "/admin/admin-pics",
      title: "Pictures",
    },
  ];

  const navigate = useNavigate();
  const admin = localStorage.getItem("admin");
  const links = localStorage.getItem("token") ? privateLinks : publicLinks;
  return (
    <S.Nav
      scrolled={scrolled}
      onScroll={() => setScrolled(!scrolled)}
      className="navbar scrolled"
    >
      <div className="container">
        <div className="navbarWrapper">
          <S.NavBrand>
            <img
              src={Logo}
              alt="LTU Basketball"
              onClick={() => navigate("/")}
            />
            {/* <img src={Logo2} alt="LKTRA" /> */}
          </S.NavBrand>

          <S.MenuIcon active={active} onClick={() => setActive(!active)}>
            <div />
            <div />
            <div />
          </S.MenuIcon>
          {admin
            ? adminLinks && (
                <S.BurgerMenu active={active}>
                  {adminLinks.map((adminlink) => (
                    <Link
                      to={adminlink.url}
                      key={adminlink.id}
                      className="navbar-item"
                      onClick={() => setActive(null)}
                    >
                      {adminlink.title}
                    </Link>
                  ))}
                  <button
                    className="button is-primary"
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("admin");
                      navigate("/");
                      setActive(null);
                    }}
                  >
                    Atsijungti
                  </button>
                  {/* <Button
                  handleClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("admin");
                    navigate("/");
                    setActive(null);
                  }}
                >
                  Sign Out
                </Button> */}
                </S.BurgerMenu>
              )
            : links && (
                <S.BurgerMenu active={active}>
                  {links.map((link) => (
                    <div className="content" key={link.id}>
                      <Link
                        to={link.url}
                        key={link.id}
                        className="navbar-item"
                        onClick={() => setActive(null)}
                      >
                        <p>
                          <b>{link.title}</b>
                        </p>
                      </Link>
                    </div>
                  ))}

                  {localStorage.getItem("token") ? (
                    <button
                      className="button is-primary"
                      onClick={() => {
                        localStorage.removeItem("token");
                        navigate("/");
                        setActive(null);
                      }}
                    >
                      Atsijungti
                    </button>
                  ) : (
                    <button
                      className="button is-primary"
                      onClick={() => {
                        navigate("/login");
                        setActive(null);
                      }}
                    >
                      Prisijungti
                    </button>
                  )}
                </S.BurgerMenu>
              )}
        </div>
      </div>
    </S.Nav>
  );
};

Nav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      active: PropTypes.func,
    })
  ),
};

export default Nav;
